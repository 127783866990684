html,
body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  background: #fff;
}

.logo {
  width: 100%;
  margin-bottom: 10px;
}

.btn {
  border: none;
  background: #589442;
  outline: none;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: 8px 25px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem;
  border-radius: 3px;
  line-height: normal;
  width: 100%;
  margin-bottom: 10px;
}

.sideBarTitle {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}

.body {
  display: flex;
  flex-direction: row;
  background: #fff;
}

.sideBar {
  width: 200px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 10px;
  padding-top: 20px;
  background: #f4f4f4;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.sideBarItem {
  border: 1px solid #589442;
  border-radius: 3px;
  padding: 10px;
  margin: 5px;
  background: #fff;
  text-align: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.sideBarItem img {
  width: 100%;
  height: 100%;
}

.page {
  flex: 1 1 auto;
  padding: 0 20px;
  margin: 20px;
  border: 1px solid green;
  background: #fff;
}

.dropZone {
  flex: 0 0 auto;
  height: 20px;
  transition: 200ms all;
}

.dropZone:nth-of-type(2n) {
  display: none;
}

.dropZone.horizontalDrag {
  width: 40px;
  height: auto;
}

.dropZone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
}

.dropZone.active,
.trashDropZone.active {
  background: #b9d3af;
  transition: 100ms all;
  border-radius: 10px;
}


.component {
  height: 80px;
  width: 80px;
  padding: 5px;
  background: #fff;
  cursor: pointer;
}

.component img {
  width: 100%;
  height: 100%;
}

.column {
  /* border: 1px solid #f4f4f4; */
  flex: 1 1 100%;
  padding: 10px;
  background: #fff;
}

.columns {
  display: flex;
  padding: 20px 0;
}

.columnContainer {
  display: flex;
}

.base {
  padding: 0.5rem 1rem;
  background-color: white;
  cursor: move;
}

.row {
  border: 2px dashed #121921;
  padding: 0;
  background: #fff;
}

.trashDropZone {
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trashDropZone img {
  width: 100%;
  height: 100%;
}

.pageContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-bottom: 100px;
}

/* Responseive (Mobile) */
@media (max-width: 768px) {
  .body {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .logo {
    width: 50%;
    margin: 10px auto;
  }

  .sideBar {
    width: 100%;
  }

}

